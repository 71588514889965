.d-flex-1 {
  flex: 1;
}

.high-auto {
  height: auto !important;
}

.h-250px {
  height: 250px;
}

.h-150px {
  height: 150px !important;
}

.w-img-30px {
  width: 30px !important;
  min-width: 30px !important;
  padding-right: 10px;
}

.h-content-for-w-img-30px {
  min-height: 30px;
  display: flex;
  align-items: center;
}

.w-img-80px {
  width: 80px !important;
  min-width: 80px !important;
}

.h-content-for-w-img-80px {
  min-height: 80px;
  display: flex;
  align-items: center;
}

.w-img-150px {
  width: 150px !important;
  min-width: 150px !important;
}

.h-content-for-w-img-150px {
  min-height: 150px;
  display: flex;
  align-items: center;
}

.w-img-160px {
  width: 160px !important;
  min-width: 120px !important;
}

.h-content-for-w-img-160px {
  min-height: 160px;
  display: flex;
  align-items: center;
  padding-right: 16px;
}

.w-img-300px {
  width: 300px !important;
  min-width: 180px !important;
}

.h-content-for-w-img-300px {
  min-height: 180px;
  display: flex;
  align-items: center;
}

.w-img-450px {
  width: 450px !important;
  min-width: 270px !important;
}

.h-content-for-w-img-450px {
  min-height: 270px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.w-img-500px {
  width: 500px !important;
  min-width: 180px !important;
}

.h-content-for-w-img-500px {
  min-height: 180px;
  display: flex;
  align-items: center;
}

.w-img-530px {
  width: 530px !important;
  min-width: 180px !important;
}

.h-content-for-w-img-530px {
  min-height: 180px;
  display: flex;
  align-items: center;
}

.w-img-60vw {
  width: 60vw !important;
  min-width: 270px !important;
}

.h-content-for-w-img-60vw {
  min-height: 270px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.w-img-100vw {
  width: 100vw !important;
  min-width: 200px !important;
}

.h-content-for-w-img-100vw {
  padding: 0px 16px;
  display: flex;
  align-items: center;
}

.w-img-page-not-found {
  width: 50vw !important;
  min-width: 270px !important;
}

.h-content-for-w-img-page-not-found {
  min-height: 270px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.w-project-logo {
  width: 120px !important;
  min-width: 100px !important;
  padding-right: 10px;
}

.h-content-for-w-project-logo {
  height: auto;
  display: flex;
  align-items: center;
}

.project-logo {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.custom-mb {
  margin-bottom: 40px;
}

@include media("<=large1100", ">=medium") {
  .w-img-page-not-found {
    width: 60vw !important;
    min-width: 270px !important;
  }

  .h-content-for-w-img-page-not-found {
    min-height: 270px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@include media("<medium") {
  .w-img-80px {
    width: 110px !important;
    min-width: 110px !important;
  }

  .h-content-for-w-img-80px {
    min-height: 110px;
    display: flex;
    align-items: center;
  }

  .w-img-150px {
    width: 150px !important;
    min-width: 150px !important;
  }

  .h-content-for-w-img-150px {
    // padding-right: 16px;
    min-height: 150px;
    display: flex;
    align-items: center;
  }

  .w-img-page-not-found {
    width: 100vw !important;
    min-width: 270px !important;
  }

  .h-content-for-w-img-page-not-found {
    min-height: 270px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@include media("<=medium") {
  .w-img-160px {
    // background-color: red;
    width: 160px !important;
    min-width: 160px !important;
  }

  .h-content-for-w-img-160px {
    padding-right: 16px;
    min-height: 160px;
    display: flex;
    align-items: center;
  }

  .custom-mb {
    margin-bottom: 0px;
  }

  .project-logo {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
}

@include media("<=small") {
  .w-img-160px {
    width: 90px !important;
    min-width: 90px !important;
  }

  .h-content-for-w-img-160px {
    padding-right: 8px;
    min-height: 90px;
    display: flex;
    align-items: center;
  }

  .w-img-450px {
    width: 450px !important;
    min-width: 200px !important;
  }

  .h-content-for-w-img-450px {
    min-height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .w-img-60vw {
    width: 100vw !important;
    min-width: 200px !important;
  }

  .h-content-for-w-img-60vw {
    min-height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .w-project-logo {
    width: 120px !important;
    min-width: 100px !important;
    padding-right: 0px;
  }

  .h-content-for-w-project-logo {
    height: auto;
    display: flex;
    align-items: center;
  }
}
