// .hero {
//   .section-inner-hero {
//     padding-top: $section--padding__mobile;
//     padding-bottom: $section--padding__mobile;
//   }
// }

.hero-inner {
  // Affects only hero full
  //   > .hero-content + .hero-figure,
  //   > .hero-figure + .hero-content {
  //     margin-top: $hero--inner-padding-v_mobile;
  //   }

  > .hero-figure {
    > a {
      display: inline-flex;
      // prevents stretching
      align-items: center;
      vertical-align: top;
    }
  }
}

.row-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.custom-padding {
  padding: 0px 0px;
}

.custom-padding-contactus {
  padding: 0px 0px;
}

@include media("<=medium") {
  .hero {
    .split-wrap {
      .split-item {
        .split-item-content {
          margin-bottom: $hero--inner-padding-v_mobile;
        }
      }

      &.invert-mobile {
        .split-item {
          .split-item-image {
            margin-bottom: $hero--inner-padding-v_mobile;
          }
        }
      }
    }
  }

  .row-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

@include media(">medium") {
  // .hero {
  //   .section-inner-hero {
  //     padding-top: $section--padding__desktop;
  //     padding-bottom: $section--padding__desktop;
  //   }
  // }

  .hero-inner {
    // Affects only hero full
    > .hero-content + .hero-figure,
    > .hero-figure + .hero-content {
      margin-top: $hero--inner-padding-v_desktop;
    }
  }

  .custom-padding {
    padding: 0px 16px;
  }

  .custom-padding-contactus {
    padding: 0px 32px;
  }
}
