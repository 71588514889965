.site-header {
  position: absolute !important;
  top: 0;
  width: 100%;
  z-index: 10 !important;
  background: color-bg(header);

  + .site-content {
    .section:first-of-type {
      padding-top: $header-height__mobile;
    }
  }

  .brand {
    margin-right: $header-nav--padding-h;
  }
}

.site-header-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: auto;
  padding-top: 15px;
  padding-bottom: 15px;
}

.header-nav {
  flex-grow: 1;

  .header-nav-inner {
    display: flex;
    flex-grow: 1;
  }

  ul {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-grow: 1;
    white-space: nowrap;
    margin-bottom: 0;

    // &:first-of-type {
    //   flex-wrap: wrap;
    // }
  }

  li {
    + .header-button {
      margin-left: $header-nav--padding-h;
    }
  }

  a:not(.button) {
    display: block;
    @include anchor-aspect(header);
    @include font-weight(header-link);
    text-transform: $link-header--transform;
    padding: 0 20px;

    .invert-color & {
      @include anchor-aspect(header, inverse);
    }
  }

  a.button {
    margin-left: $header-nav--padding-h;
  }
}

.header-nav-center:first-of-type {
  flex-grow: 1;
  justify-content: flex-end;
}

.header-nav-right {
  justify-content: flex-end;

  + .header-nav-right {
    flex-grow: 0;
  }
}

.header-nav-toggle {
  display: none;
}

.header-highlight {
  font-weight: bold !important;
  background-color: get-color(text, highlight) !important;
  border-radius: 5px !important;
  color: color(header-link-hover) !important;
  padding: 12px 20px !important;
}

.custom-header-mb {
  margin-bottom: 48px;
}

@include media("<=large1100") {
  .header-nav-toggle {
    display: block;

    // Header navigation when the hamburger is a previous sibling
    + .header-nav {
      flex-direction: column;
      position: absolute;
      left: 1;
      right: 0;
      top: 63%;
      z-index: 9999;
      background: color-bg(menu-mobile);
      max-height: 0;
      overflow: hidden;
      transition: max-height 0.25s ease-in-out, opacity 0.15s;
      filter: drop-shadow(rgba(0, 0, 0, 0.25) 1px 5px 5px);
      margin: 5px 10px;
      border-radius: 5px;
      width: 40%;
      min-width: 300px;

      &.is-active {
        opacity: 1;
      }

      .header-nav-inner {
        flex-direction: column;
        padding: 0px 15px 0px 5px;
      }

      ul {
        display: block;
        text-align: center;

        a:not(.button) {
          display: inline-flex;
          @include anchor-aspect(header-mobile);
          padding-top: 20px;
          padding-bottom: 20px;
        }
      }

      a.button {
        margin-left: 0;
        margin-top: $header-nav--padding-v__mobile / 2;
        margin-bottom: $header-nav--padding-v__mobile / 2;
      }
    }
  }

  .header-highlight {
    font-weight: bold !important;
    background-color: get-color(text, highlight) !important;
    border-radius: 5px !important;
    color: color(header-link-hover) !important;
    width: 100%;
    justify-content: center;
    padding: 20px 20px !important;
  }

  .menu-w-100 {
    width: 100% !important;
    justify-content: center;
  }
}

@include media(">medium") {
  .site-header {
    + .site-content {
      .section:first-of-type {
        padding-top: $header-height__desktop;
      }
    }
  }

  .site-header-inner {
    height: auto;
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

@include media("<medium") {
  .header-nav-toggle {
    display: block;

    // Header navigation when the hamburger is a previous sibling
    + .header-nav {
      flex-direction: column;
      position: absolute;
      left: -3%;
      right: 1;
      top: 70%;
      z-index: 9999;
      background: color-bg(menu-mobile);
      max-height: 0;
      overflow: hidden;
      transition: max-height 0.25s ease-in-out, opacity 0.15s;
      filter: drop-shadow(rgba(0, 0, 0, 0.25) 1px 5px 5px);
      margin: 5px 10px;
      border-radius: 5px;
      width: calc(100% - 0px);
      min-width: auto;

      &.is-active {
        opacity: 1;
      }

      .header-nav-inner {
        flex-direction: column;
        padding: 0px 15px 0px 5px;
      }

      ul {
        display: block;
        text-align: center;

        a:not(.button) {
          display: inline-flex;
          @include anchor-aspect(header-mobile);
          // padding-top: $header-nav--padding-v__mobile / 2;
          // padding-bottom: $header-nav--padding-v__mobile / 2;
        }
      }

      a.button {
        margin-left: 0;
        margin-top: $header-nav--padding-v__mobile / 2;
        margin-bottom: $header-nav--padding-v__mobile / 2;
      }
    }
  }

  .custom-header-mb {
    margin-bottom: 16px;
  }
}
