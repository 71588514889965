.section-inner {
  position: relative; // To always display inner elements above pseudo decorative stuff
  padding-top: $section--padding__mobile;
  padding-bottom: 220px;
}

.section-header {
  padding-bottom: $section-header--padding__mobile;
}

.hero .section-inner-hero {
  padding-top: $section--padding__mobile;
  padding-bottom: $section--padding__mobile;
}

.section-inner-whatwedo {
  padding-top: $section--padding__mobile;
  padding-bottom: $section--padding__mobile;
}

.section-inner-ourservice {
  padding-top: $section--padding__mobile;
  padding-bottom: $section--padding__mobile;
}

.section-inner-ourwork {
  padding-top: $section--padding__mobile;
  padding-bottom: 0px;
}

.section-inner-whoweare {
  padding-top: $section--padding__mobile;
  padding-bottom: $section--padding__mobile;
}

.section-inner-howwework {
  padding-top: $section--padding__mobile;
  padding-bottom: $section--padding__mobile;
}
.section-inner-contactus {
  padding-top: $section--padding__mobile;
  padding-bottom: $section--padding__mobile;
}

.section-inner-nav-link {
  padding-top: $section--padding__mobile;
  padding-bottom: 0px;
}

.section-inner-position-content {
  padding-top: 70px;
  padding-bottom: 0px;
}

.mt-contactus {
  margin-top: 32px;
}

.nav-link-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 24px;
}

.project-detail-header {
  font-size: 44px;
  font-weight: 800;
  padding: 0px;
  margin-bottom: 20px;
}

.position-detail-header {
  font-size: 44px;
  font-weight: 800;
  padding: 0px;
  margin-bottom: 10px;
}

.position-detail-top-content {
  padding: 0px;
}

.position-detail-title {
  padding: 0px;
  margin: 0px 0px 10px 0px;
}

@include media(">medium") {
  .section-inner {
    padding-top: $section--padding__desktop;
    padding-bottom: 180px;
  }

  .section-header {
    padding-bottom: $section-header--padding__desktop;
  }

  .hero .section-inner-hero {
    padding-top: $section--padding__desktop;
    padding-bottom: $section--padding__desktop;
  }

  .section-inner-whatwedo {
    padding-top: $section--padding__desktop;
    padding-bottom: $section--padding__desktop;
  }

  .section-inner-ourservice {
    padding-top: $section--padding__desktop;
    padding-bottom: $section--padding__desktop;
  }

  .section-inner-ourwork {
    padding-top: $section--padding__desktop;
    padding-bottom: 0px;
  }

  .section-inner-whoweare {
    padding-top: $section--padding__desktop;
    padding-bottom: $section--padding__desktop;
  }

  .section-inner-howwework {
    padding-top: $section--padding__desktop;
    padding-bottom: $section--padding__desktop;
  }
  .section-inner-contactus {
    padding-top: $section--padding__desktop;
    padding-bottom: $section--padding__desktop;
  }

  .section-inner-nav-link {
    padding-top: $section--padding__desktop;
    padding-bottom: 0px;
  }
}

@include media("<=medium") {
  .project-detail-header {
    font-size: 26px;
    font-weight: 800;
    padding: 0px 10px 0px 10px;
    margin-bottom: 20px;
  }

  .position-detail-header {
    font-size: 26px;
    font-weight: 800;
    padding: 0px 10px;
    margin-bottom: 10px;
  }

  .position-detail-top-content {
    padding: 0px 10px;
  }

  .position-detail-title {
    padding: 0px 10px;
    margin: 0px 0px 10px 0px;
  }

  .nav-link-row {
    padding: 0px 10px 24px 10px;
  }
}

@include media("<=small") {
  .nav-link-row {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px 10px 24px 10px;
  }
}

.section-process-review {
  h1{
    margin: 0;
  }
  h2{
    color: #A93346;
    font-size: 40px;
    margin: 0;
  }
  margin-bottom: 200px;
  width: 100%;
  position: relative;
  .container-sm{
    position: relative;
    z-index: 1;
  }
  .box-reviw {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 40px;
    .title{
    text-align: center;
      width: 200px;
    }
    .text{
      width: auto;
    }
    div {
      padding: 15px;
    }
    .number{
      width: 100px;
    }
  }
  .container-text {
    padding: 10px 20px;
    background: #f2f2f2;
    display: grid;
    grid-template-columns: 200px auto ;
    flex-direction: row;
    align-items: center; 
    min-height: 140px;
  }
   @media (max-width: 600px) {
      .container-text {
        grid-template-columns: 1fr; /* Change to a single column layout */
      }
    }
}
