.features-tiles {
  .section-inner {
    padding-top: $features-tiles--padding-t__mobile;
    padding-bottom: $features-tiles--padding-b__mobile;
  }

  .section-header {
    padding-bottom: $features-tiles-header--padding__mobile;
  }

  .tiles-wrap {
    @if ($features-tiles-items--padding__mobile !=null) {
      margin-right: -($features-tiles-items--padding__mobile / 2);
      margin-left: -($features-tiles-items--padding__mobile / 2);
      margin-top: -($features-tiles-items--padding__mobile / 2);

      &:last-of-type {
        margin-bottom: -($features-tiles-items--padding__mobile / 2);
      }

      &:not(:last-of-type) {
        margin-bottom: ($features-tiles-items--padding__mobile / 2);
      }
    }

    &.push-left {
      &::after {
        flex-basis: $features-tiles-item--width;
        max-width: $features-tiles-item--width;

        @if ($features-tiles-items--padding__mobile !=null) {
          padding-left: $features-tiles-items--padding__mobile / 2;
          padding-right: $features-tiles-items--padding__mobile / 2;
        }
      }
    }
  }

  .tiles-item {
    // background-color: pink;
    flex-basis: 100%;
    width: 100%;
    margin-bottom: 48px;

    @if ($tiles-items--padding__mobile !=null) {
      padding: $tiles-items--padding__mobile / 2;
    }

    // flex-basis: $features-tiles-item--width;
    // max-width: $features-tiles-item--width;
    // @if ( $features-tiles-items--padding__mobile != null ) {
    // 	padding: $features-tiles-items--padding__mobile / 2;
    // }
  }

  .tiles-item-our-story {
    // background-color: yellow;
    flex-basis: 100%;
    width: 100%;
    margin-bottom: 16px;
  }

  .tiles-item-project-img {
    flex-basis: 100%;
    width: 100%;
    margin-bottom: 16px;
    padding: 30px 0px 0px 0px;
  }

  .tiles-customer-logo {
    flex-basis: 50%;
    width: 50%;
    margin-bottom: 0px;
    padding: 5px;
  }

  .tiles-item-inner {
    padding-top: $features-tiles-item--inner-padding-v;
    padding-bottom: $features-tiles-item--inner-padding-v;
    padding-left: $features-tiles-item--inner-padding-h;
    padding-right: $features-tiles-item--inner-padding-h;
  }
}

.tiles-item-career-top-left {
  flex-basis: 100%;
  width: 100%;
  padding: 16px;
}

.tiles-item-career-top-right {
  flex-basis: 100%;
  width: 100%;
  margin-bottom: 0px;
  padding: 20px 20px 0px 20px;
}

.tiles-item-contactus-top-left {
  flex-basis: 100%;
  width: 100%;
  padding: 16px;
}

.tiles-item-contactus-top-right {
  flex-basis: 100%;
  width: 100%;
  margin-bottom: 0px;
  padding: 20px 20px 0px 20px;
}

.tiles-item-contactus-bottom-left {
  background-color: get-color(bg, 1);
  flex-basis: 100%;
  width: 100%;
  padding: 24px;
}

.tiles-item-contactus-bottom-right {
  background-color: get-color(bg, 3);
  flex-basis: 100%;
  width: 100%;
  margin-bottom: 48px;
  padding: 24px;
}

.item-contactus-bottom {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
}

.justify-title-contactus {
  text-align: center;
}

.justify-detail-contactus {
  text-align: left;
  word-wrap: break-word;
  word-break: break-word;
}

.row>* {
  padding-left: calc(var(--bs-gutter-x) * 0.7) !important;
  padding-right: calc(var(--bs-gutter-x) * 0.7) !important;
}

.row>.drop-message-label {
  padding-left: calc(var(--bs-gutter-x) * 0.7) !important;
  padding-right: calc(var(--bs-gutter-x) * 0.5) !important;
}

.drop-message-title {
  margin-bottom: 6px !important;
}

.text-invert-contactus {
  color: get-color(text, invert);
  text-align: left;
}

.link-invert-contactus {
  color: get-color(text, invert) !important;
  text-decoration: none !important;
  text-align: left;
  text-decoration: underline !important;

  &:hover {
    color: darken(#f2f2f2, 6%) !important;
    text-decoration: underline !important;
    font-weight: 600 !important;
  }
}

.custom-pt-contactus {
  padding-top: 0px !important;
}

.w-100-contactus {
  width: 99%;
}

.tiles-item-whoweare-top-left {
  flex-basis: 100%;
  width: 100%;
  padding: 16px;
  font-style: italic;
}

.tiles-item-whoweare-top-right {
  flex-basis: 100%;
  width: 100%;
  padding: 16px;
  font-style: italic;
}

.tiles-item-whoweare-bottom-left {
  flex-basis: 100%;
  width: 100%;
  padding: 16px;
  font-style: italic;
}

.tiles-item-whoweare-bottom-right {
  flex-basis: 100%;
  width: 100%;
  padding: 16px;
  font-style: italic;
  text-align: left;
}

.tiles-item-howwework-img-left {
  flex-basis: 100%;
  width: 100%;
  padding: 16px;
}

.tiles-item-howwework-text-right {
  flex-basis: 100%;
  width: 100%;
  padding: 16px;
}

.tiles-item-howwework-text-left {
  flex-basis: 100%;
  width: 100%;
  padding: 16px;
}

.tiles-item-howwework-img-right {
  flex-basis: 100%;
  width: 100%;
  padding: 16px;
}

.tiles-item-ourproject-img {
  flex-basis: 100%;
  width: 100%;
  margin-bottom: 0px;
  padding: 32px 12px 12px 12px;
}

.tiles-item-ourproject {
  flex-basis: 100%;
  width: 100%;
  margin-bottom: 0px;
  padding: 12px 12px 32px 12px;
}

.tiles-item-project-img-left {
  flex-basis: 100%;
  width: 100%;
  padding: 16px;
}

.tiles-item-project-text-right {
  flex-basis: 100%;
  width: 100%;
  padding: 16px;
}

.tiles-item-project-text-left {
  flex-basis: 100%;
  width: 100%;
  padding: 16px;
}

.tiles-item-project-img-right {
  flex-basis: 100%;
  width: 100%;
  padding: 16px;
}

.project-tag-outer {
  padding: 5px 10px 5px 0px;
}

.project-tag-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
}

.project-tag {
  padding: 3px 20px;
  background-color: get-color(bg, 2);
  border-radius: 12px;
  font-size: 14px;

  a {
    color: get-color(text, base) !important;
  }

  &:hover {
    font-weight: 600 !important;
  }
}

.show-content-md-up {
  display: none;
}

.show-content-sm-down {
  display: flex;
}

.form_group_recaptcha>div>div {
  display: flex;
  place-content: center;
  transform: scale(1);
}

.verifed-message {
  color: get-color(text, highlight);
}

.project-detail-top {
  font-size: 14px;
}

.project-detail-bottom {
  text-align: right;
  font-style: italic;
  font-size: 14px;
}

.tiles-item-career-list {
  flex-basis: 100%;
  width: 100%;
  padding: 8px 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-weight: 550;
  color: get-color(text, highlight);
  background-color: get-color(bg, 2);
  border-radius: 10px;

  &:hover {
    background-color: lighten(get-color(bg, 3), 50%);
  }
}

@include media(">medium") {
  .features-tiles {
    .section-inner {
      padding-top: $features-tiles--padding-t__desktop;
      padding-bottom: $features-tiles--padding-b__desktop;
    }

    .section-header {
      padding-bottom: $features-tiles-header--padding__desktop;
    }

    @if ($features-tiles-items--padding__desktop !=null) {
      .tiles-wrap {
        margin-right: -($features-tiles-items--padding__desktop / 2);
        margin-left: -($features-tiles-items--padding__desktop / 2);
        margin-top: -($features-tiles-items--padding__desktop / 2);

        &:last-of-type {
          margin-bottom: -($features-tiles-items--padding__desktop / 2);
        }

        &:not(:last-of-type) {
          margin-bottom: ($features-tiles-items--padding__desktop / 2);
        }

        &.push-left {
          &::after {
            padding-left: $features-tiles-items--padding__desktop / 2;
            padding-right: $features-tiles-items--padding__desktop / 2;
          }
        }
      }

      .tiles-item {
        padding: $features-tiles-items--padding__desktop / 2;
      }
    }
  }

  .features-tiles {
    .tiles-item {
      // background-color: greenyellow;
      flex-basis: 50%;
      width: 50%;
      margin-bottom: 0px;

      @if ($tiles-items--padding__desktop !=null) {
        padding: $tiles-items--padding__desktop / 2;
      }
    }

    .tiles-item-our-story {
      // background-color: burlywood;
      flex-basis: 100%;
      width: 100%;
      margin-bottom: 24px;
    }

    .tiles-item-project-img {
      flex-basis: 100%;
      width: 100%;
      margin-bottom: 24px;
      padding: 60px 0px 30px 0px;
    }

    .tiles-customer-logo {
      flex-basis: 16%;
      width: 16%;
      margin-bottom: 0px;
      padding: 5px;
    }
  }

  .tiles-item-career-top-left {
    flex-basis: 65%;
    width: 65%;
    padding: 24px 24px 24px 100px;
    margin-bottom: 0px;
  }

  .tiles-item-career-top-right {
    flex-basis: 35%;
    width: 35%;
    margin-bottom: 0px;
    padding: 0px 100px 0px 24px;
  }

  .tiles-item-contactus-top-left {
    flex-basis: 60%;
    width: 60%;
    padding: 24px 24px 24px 100px;
    margin-bottom: 0px;
  }

  .tiles-item-contactus-top-right {
    flex-basis: 40%;
    width: 40%;
    margin-bottom: 0px;
    padding: 0px 100px 0px 24px;
  }

  .tiles-item-contactus-bottom-left {
    background-color: get-color(bg, 1);
    flex-basis: 60%;
    width: 60%;
    padding: 32px;
    margin-bottom: 0px;
  }

  .tiles-item-contactus-bottom-right {
    background-color: get-color(bg, 3);
    flex-basis: 40%;
    width: 40%;
    padding: 32px;
    margin-bottom: 0px;
  }

  .item-contactus-bottom {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: 100%;
    overflow: hidden;
  }

  .justify-title-contactus {
    text-align: left;
  }

  .custom-pt-contactus {
    padding-top: 15px !important;
  }

  .tiles-item-whoweare-top-left {
    flex-basis: 60%;
    width: 60%;
    padding: 24px 24px 24px 100px;
    margin-bottom: 0px;
    font-style: italic;
    text-align: right;
  }

  .tiles-item-whoweare-top-right {
    flex-basis: 40%;
    width: 40%;
    margin-bottom: 0px;
    padding: 0px 100px 0px 24px;
    font-style: italic;
  }

  .tiles-item-whoweare-bottom-left {
    flex-basis: 40%;
    width: 40%;
    margin-bottom: 0px;
    padding: 0px 24px 0px 100px;
    font-style: italic;
  }

  .tiles-item-whoweare-bottom-right {
    flex-basis: 60%;
    width: 60%;
    padding: 24px 100px 24px 24px;
    margin-bottom: 0px;
    font-style: italic;
    text-align: left;
  }

  .tiles-item-howwework-img-left {
    flex-basis: 25%;
    width: 25%;
    margin-bottom: 0px;
    padding: 0px 24px 0px 50px;
  }

  .tiles-item-howwework-text-right {
    flex-basis: 75%;
    width: 75%;
    padding: 24px 160px 24px 24px;
    margin-bottom: 0px;
    text-align: left;
  }

  .tiles-item-howwework-text-left {
    flex-basis: 75%;
    width: 75%;
    padding: 24px 24px 24px 160px;
    margin-bottom: 0px;
    text-align: right;
  }

  .tiles-item-howwework-img-right {
    flex-basis: 25%;
    width: 25%;
    margin-bottom: 0px;
    padding: 0px 50px 0px 24px;
  }

  .tiles-item-ourproject-img {
    flex-basis: 50%;
    width: 50%;
    margin-bottom: 0px;
    padding: 48px 24px 48px 24px;
  }

  .tiles-item-ourproject {
    flex-basis: 50%;
    width: 50%;
    margin-bottom: 0px;
    padding: 48px 24px 48px 24px;
  }

  .tiles-item-project-img-left {
    flex-basis: 50%;
    width: 50%;
    margin-bottom: 0px;
    padding: 0px 24px 0px 24px;
    // background-color: pink;
  }

  .tiles-item-project-text-right {
    flex-basis: 50%;
    width: 50%;
    padding: 24px;
    margin-bottom: 0px;
    text-align: left;
    // background-color: orange;
  }

  .tiles-item-project-text-left {
    flex-basis: 50%;
    width: 50%;
    padding: 24px;
    margin-bottom: 0px;
    text-align: right;
  }

  .tiles-item-project-img-right {
    flex-basis: 50%;
    width: 50%;
    margin-bottom: 0px;
    padding: 0px 24px 0px 24px;
  }

  .show-content-md-up {
    display: flex;
  }

  .show-content-sm-down {
    display: none;
  }

  .form_group_recaptcha>div>div {
    display: flex;
    place-content: center;
    transform: scale(0.72);
  }
}

@include media("<=medium") {
  .project-detail-bottom {
    text-align: left;
    font-style: italic;
    font-size: 14px;
  }

  .project-tag-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }

  .tiles-item-career-list {
    flex-basis: 100%;
    width: 100%;
    padding: 8px 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-weight: 550;
    color: get-color(text, highlight);
    background-color: get-color(bg, 2);
  }
}

@include media("<=small") {
  .tiles-item-contactus-bottom-left {
    background-color: get-color(bg, 1);
    flex-basis: 100%;
    width: 100%;
    padding: 24px 10px 10px 10px;
  }

  .form_group_recaptcha>div>div {
    display: flex;
    place-content: center;
    transform: scale(0.58);
  }
}

// new section
.contain-header-our-srevices-05 {
  display: flex;
  align-items: center;

  h1 {
    font-size: 44px !important;
  }
}