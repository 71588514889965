.testimonial {
  .section-inner {
    padding-top: $testimonial--padding-t__mobile;
    padding-bottom: $testimonial--padding-b__mobile;
  }

  .section-header {
    padding-bottom: $testimonial-header--padding__mobile;
  }

  .tiles-wrap {
    @if ($testimonial-items--padding__mobile != null) {
      margin-right: -($testimonial-items--padding__mobile / 2);
      margin-left: -($testimonial-items--padding__mobile / 2);
      margin-top: -($testimonial-items--padding__mobile / 2);

      &:last-of-type {
        margin-bottom: -($testimonial-items--padding__mobile / 2);
      }

      &:not(:last-of-type) {
        margin-bottom: ($testimonial-items--padding__mobile / 2);
      }
    }

    &.push-left {
      &::after {
        flex-basis: $testimonial-item--width;
        max-width: $testimonial-item--width;
        @if ($testimonial-items--padding__mobile != null) {
          padding-left: $testimonial-items--padding__mobile / 2;
          padding-right: $testimonial-items--padding__mobile / 2;
        }
      }
    }
  }

  .tiles-item {
    // background-color: pink;
    flex-basis: 100%;
    width: 100%;
    margin-bottom: 48px;
    @if ($tiles-items--padding__mobile != null) {
      padding: $tiles-items--padding__mobile / 2;
    }
  }

  .tiles-item-our-work {
    // background-color: violet;
    flex-basis: 100%;
    width: 100%;
    margin-bottom: 24px;
    padding: 0px 16px;
  }

  .last-tiles-item-our-work {
    flex-basis: 100%;
    width: 100%;
    padding: 0px 16px;
  }

  .item-our-work {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: get-color(bg, 2);
    border-radius: 10px;
    overflow: hidden;
    padding: 16px;
  }

  .tiles-item-inner {
    padding-top: $testimonial-item--inner-padding-v;
    padding-bottom: $testimonial-item--inner-padding-v;
    padding-left: $testimonial-item--inner-padding-h;
    padding-right: $testimonial-item--inner-padding-h;
  }
}

.testimonial-item-content {
  flex-grow: 1;
}

.testimonial-item-source {
  display: inline-flex; // to allow centering when using .content-center on a parent tag
  flex-wrap: wrap;
  align-items: center;
}

.title-our-work {
  color: get-color(text, highlight);
}

.detail-our-work {
  font-style: italic !important;
}

.nav-link {
  display: flex;
  justify-content: flex-start;
  color: get-color(text, highlight) !important;
  &:hover {
    color: get-color(text, highlight) !important;
    text-decoration: underline !important;
  }
}

.detail-link {
  display: flex;
  justify-content: flex-end;
  color: get-color(text, highlight);
  font-style: italic !important;
  padding-top: 5px;
}

.detail-link-project-content {
  display: flex;
  justify-content: flex-end;
  color: get-color(text, highlight);
  font-style: italic !important;
  padding-top: 10px;
  padding-bottom: 10px;
}

.position-detail-link {
  display: flex;
  justify-content: flex-start;
  color: get-color(text, base) !important;
  // font-style: italic !important;
  font-size: 14px;
  padding-left: 24px;
}

.hover-position-detail-link {
  color: get-color(text, base) !important;
  font-size: 14px;
  &:hover {
    text-decoration: underline;
    color: get-color(text, base) !important;
  }
}

.icon-up-link {
  display: flex;
  justify-content: flex-end;
  // padding: 50px 0px 0px 0px;
}

.hover-icon-up-link {
  color: get-color(text, base) !important;
  font-size: 20px !important;
  &:hover {
    text-decoration: underline;
    color: get-color(text, base) !important;
  }
}

.see-more-link {
  display: flex;
  justify-content: flex-end;
  color: get-color(text, highlight);
  font-style: italic !important;
  font-weight: 500;
  font-size: 20px;
  padding-right: 16px;
}

.hover-see-more-link {
  &:hover {
    font-weight: 600;
    text-decoration: underline;
  }
}

.hover-career-list-link {
  &:hover {
    font-style: italic;
  }
  &:hover .career-title {
    text-decoration: underline;
  }
}

.bg-img-our-work {
  width: 100vw;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(../../../images/home/our_work_bg.svg);
}

.bg-img-contact-us {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom;
  background-image: url(../../../images/contact-us/contact_us_bg_02.svg);
  padding-top: 0px;
  padding-bottom: 24px;
  padding-left: 24px;
  padding-right: 24px;
  border-radius: 0px 0px 16px 16px;
}

@include media(">medium") {
  .testimonial {
    .section-inner {
      padding-top: $testimonial--padding-t__desktop;
      padding-bottom: $testimonial--padding-b__desktop;
    }

    .section-header {
      padding-bottom: $testimonial-header--padding__desktop;
    }

    @if ($testimonial-items--padding__desktop != null) {
      .tiles-wrap {
        margin-right: -($testimonial-items--padding__desktop / 2);
        margin-left: -($testimonial-items--padding__desktop / 2);
        margin-top: -($testimonial-items--padding__desktop / 2);

        &:last-of-type {
          margin-bottom: -($testimonial-items--padding__desktop / 2);
        }

        &:not(:last-of-type) {
          margin-bottom: ($testimonial-items--padding__desktop / 2);
        }

        &.push-left {
          &::after {
            padding-left: $testimonial-items--padding__desktop / 2;
            padding-right: $testimonial-items--padding__desktop / 2;
          }
        }
      }
    }

    .tiles-item {
      // background-color: greenyellow;
      flex-basis: 33.33%;
      width: 33.33%;
      margin-bottom: 0px;
      @if ($tiles-items--padding__desktop != null) {
        padding: $tiles-items--padding__desktop / 2;
      }
    }

    .tiles-item-our-work {
      // background-color: orange;
      flex-basis: 33.33%;
      width: 33.33%;
      margin-bottom: 0px;
      padding: 16px;
    }

    .last-tiles-item-our-work {
      flex-basis: 33.33%;
      width: 33.33%;
      margin-bottom: 0px;
      padding: 16px 16px 16px 16px;
    }

    .item-our-work {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      background-color: get-color(bg, 2);
      border-radius: 10px;
      overflow: hidden;
      padding: 20px;
    }
  }

  .bg-img-our-work {
    width: 100vw;
    position: relative;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center bottom;
    background-image: url(../../../images/home/our_work_bg.svg);
  }

  .bg-img-contact-us {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: bottom;
    background-image: url(../../../images/contact-us/contact_us_bg_01.svg);
    padding-top: 0px;
    padding-bottom: 64px;
    padding-left: 64px;
    padding-right: 64px;
    border-radius: 0px 0px 16px 16px;
  }
}
