[class*="illustration-"] {
  position: relative;

  &::after {
    pointer-events: none;
  }
}

[class*="illustration-element-"]::after {
  z-index: -1;
}

[class*="illustration-section-"]::after {
  z-index: -2;
}

// Hero illustration
.illustration-section-01::after {
  @include illustration("other/illustration-section-01.svg", 100%, 286px, top);
}

// Features illustration 02
.illustration-section-02::after {
  @include illustration(
    "other/illustration-section-02.svg",
    100%,
    253px,
    null,
    null,
    30px
  );
}

// Top Background
.bg-top::after {
  @include illustration("bg-top.svg", 100%, 286px, top);
}

// Bottom Background
.bg-bottom::after {
  @include illustration("bg-bottom.svg", 100%, 286px, bottom);
}
